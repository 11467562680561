import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: (data: any) => void; // Adjust the type as per your needs
};

export default function FormProvider({ children, onSubmit, methods }: Props) {
  const handleSubmit = methods.handleSubmit((data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  });

  return (
    <Form {...methods}>
      <form onSubmit={handleSubmit}>{children}</form>
    </Form>
  );
}
