import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import UsersListPage from 'src/pages/dashboard/management/users-list';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));

// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/profile/profile'));
// Company
const CompanyAccountPage = lazy(() => import('src/pages/dashboard/management/account'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// CHECKOUT PAGE
const CheckoutPage = lazy(() => import('src/pages/dashboard/payment/checkout'));
const SuccessPage = lazy(() => import('src/pages/dashboard/payment/success'));
// Products
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductPassPage = lazy(() => import('src/pages/dashboard/product/pass'));
const ProductTemplatePage = lazy(() => import('src/pages/dashboard/product/template'));
const ProductVersionPage = lazy(() => import('src/pages/dashboard/product/version'));
// Data 
const DataTreePage = lazy(() => import('src/pages/dashboard/data/tree'));
// Analytics PAGE
const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics/matomo-analytics'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
     // { path: 'ecommerce', element: <OverviewEcommercePage /> },

      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'account', element: <UserProfilePage /> },
        ],
      },
      {
        path: 'management',
        children: [
          { element: <CompanyAccountPage />, index: true },
          { path: 'account', element: <CompanyAccountPage /> },
          { path: 'userslist', element: <UsersListPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: 'template/:id', element: <ProductTemplatePage /> },
          { path: 'pass/:id', element: <ProductPassPage /> },
          { path: 'version/:id', element: <ProductVersionPage /> },
        ],
      },
      {
        path: 'data',
        children: [
          { element: <DataTreePage />, index: true },
        ],
      },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { 
        path: 'checkout',
        children: [
          { element: <CheckoutPage />, index: true },
          { path: 'success/:session_id', element: <SuccessPage /> },
        ],
      },
      { 
        path: 'analytics',
        children: [
          { element: <AnalyticsPage />, index: true },
        ],
      },
    ],
  },
];
