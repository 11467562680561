// hooks
import { useLocales } from 'src/locales';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// components
import { Grid, Card, Typography } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export default function UserNewForm({ open, onClose }: Props) {
  const { t } = useLocales();
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        {/* <FormProvider methods={methods} onSubmit={onSubmit}> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Typography variant="body2">{t('mngmt.userlist.newUserText')}</Typography>
              <Typography variant="body2">{t('mngmt.userlist.userText')}</Typography>
              <Typography variant="body2">{t('mngmt.userlist.company')}</Typography>
              <Typography variant="body2">{t('mngmt.userlist.firstname')}</Typography>
              <Typography variant="body2">{t('mngmt.userlist.lastname')}</Typography>
              <Typography variant="body2">{t('mngmt.userlist.email')}</Typography>
              <Typography variant="body2">{t('mngmt.userlist.operation')}</Typography>
              <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Save Changes
                  </LoadingButton> */}
              </DialogActions>
            </Card>
          </Grid>
        </Grid>
        {/* </FormProvider> */}
      </DialogContent>
    </Dialog>
  );
}
