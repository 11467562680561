//
import { v4 as uuid } from 'uuid';
import { ITemplate } from 'src/types/templates';
import { _widgets } from './_widgets';
// ----------------------------------------------------------------------

function generateId(length: number) {
  return Array.from({ length }, () => Math.floor(Math.random() * 16).toString(16)).join('');
}

export const _newtemplate: ITemplate = {
  id: generateId(10),
  name: '',
  createdAt: new Date().toString(),
  createdBy: '',
  industry: 'Custom',
  changeProduct: '',
  tempChangeAt: '',
  pass: {
    mode: 'N',
    version: '',
    releaseDate: '',
    widgets: [
      _widgets[0],
      _widgets[1],
      _widgets[2],
    ],
    design: {
      style: {
        general: {
          topButton: true,
        },
        buttons: {
          variant: '',
          size: '',
          textColor: '',
          buttonColor: '',
        },
        cards: {
          cardDesign: true,
          borderradius: 0,
          color: '',
        },
        icons: {
          showIcons: true,
          iconColor: '',
          showSubIcons: true,
          subIconColor: '',
        },
        colors: {
          primaryColor: '',
          secondaryColor: '',
          backgroundColor: '',
          generalTextColor: '',
          elementsColor: '',
        },
      },
      typography: {
        headlines: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        paragraphs: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        tags: {
          font: '',
          weight: '',
          color: '',
          tagColor: '',
        },
        table: {
          font: '',
          weight: '',
          color: '',
        },
      },
    }
  },
};
