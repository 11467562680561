//
import { ITemplate } from 'src/types/templates';
import { _widgets } from './_widgets';
// ----------------------------------------------------------------------

const Template1: ITemplate = {
  id: '000000001',
  name: 'Template1',
  createdAt: new Date().toString(),
  createdBy: '',
  industry: 'Wine',
  changeProduct: '',
  tempChangeAt: '',
  pass: {
    mode: 'C',
    version: '',
    releaseDate: '',
    widgets: [
      _widgets[1],
      _widgets[3],
    ],
    design: {
      style: {
        general: {
          topButton: true,
        },
        buttons: {
          variant: '',
          size: '',
          textColor: '',
          buttonColor: '',
        },
        cards: {
          cardDesign: true,
          borderradius: 0,
          color: '',
        },
        icons: {
          showIcons: true,
          iconColor: '',
          showSubIcons: true,
          subIconColor: '',
        },
        colors: {
          primaryColor: '',
          secondaryColor: '',
          backgroundColor: '',
          generalTextColor: '',
          elementsColor: '',
        },
      },
      typography: {
        headlines: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        paragraphs: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        tags: {
          font: '',
          weight: '',
          color: '',
          tagColor: '',
        },
        table: {
          font: '',
          weight: '',
          color: '',
        },
      },
    },
  },
};

const Template2: ITemplate = {
  id: '000000002',
  name: 'Template2',
  createdAt: new Date().toString(),
  createdBy: '',
  industry: 'Wine',
  changeProduct: '',
  tempChangeAt: '',
  pass: {
    mode: 'C',
    version: '',
    releaseDate: '',
    widgets: [
      _widgets[1],
      _widgets[3],
    ],
    design: {
      style: {
        general: {
          topButton: true,
        },
        buttons: {
          variant: '',
          size: '',
          textColor: '',
          buttonColor: '',
        },
        cards: {
          cardDesign: true,
          borderradius: 0,
          color: '',
        },
        icons: {
          showIcons: true,
          iconColor: '',
          showSubIcons: true,
          subIconColor: '',
        },
        colors: {
          primaryColor: '',
          secondaryColor: '',
          backgroundColor: '',
          generalTextColor: '',
          elementsColor: '',
        },
      },
      typography: {
        headlines: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        paragraphs: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        tags: {
          font: '',
          weight: '',
          color: '',
          tagColor: '',
        },
        table: {
          font: '',
          weight: '',
          color: '',
        },
      },
    },
  },
};

export const _templates: ITemplate[] = [];
