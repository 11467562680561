import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import LabelLayout from 'src/layouts/label';
// components
import { SplashScreen } from 'src/components/loading-screen';
import LabelGuard from 'src/auth/guard/label-guard';

// ----------------------------------------------------------------------
const GS1Page = lazy(() => import('src/pages/gs1'));

// ----------------------------------------------------------------------
export const gs1Routes = [
  {
    path: '01',
    element: (
      <LabelGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </LabelGuard>
    ),
    children: [
      {
        path: ':gtin',
        element: (
          <LabelLayout>
            <GS1Page />
          </LabelLayout>
        ),
      },
      {
        path: ':gtin/*',
        element: (
          <LabelLayout>
            <GS1Page />
          </LabelLayout>
        ),
      },
    ],
  },
];
