import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { IUserItem } from 'src/types/user';
import { collection, query, where, getDocs, getFirestore, doc, getDoc } from 'firebase/firestore';
import { FIREBASE_API, FIREBASE_DATABASE_ID } from '../config-global';


const firebaseApp = firebase.initializeApp(FIREBASE_API);

const firestore = getFirestore(firebaseApp, `${FIREBASE_DATABASE_ID}`);

export { firestore };
export default firebase;


function getRoleByUid(
  users: { role: string; uid: string }[],
  targetUid: string
): string | undefined {
  const user = users.find((u) => u.uid === targetUid);
  return user ? user.role : undefined;
}

export function getFBRole(
  userId: string, companyId: string
): Promise<[string, boolean, string]> {

  const q = query(
    collection(firestore, "company"),
    where('__name__', '==', companyId)
  );

  return getDocs(q)
    .then((querySnapshot) => {
      let isLoading: boolean = true;
      const error: string = '';
      const data = querySnapshot.docs.map((docu) => docu.data());
      let role = '';

      if (data) {
        role = getRoleByUid(data[0].user, userId) || '';
        isLoading = false;
      }

      return [role, isLoading, error] as [string, boolean, string];
    })
    .catch((err) => {
      console.error(err);
      return [[], false, err];
    }) as Promise<[string, boolean, string]>;
}

export async function getFBUsersComp(
  companyId: string
): Promise<[IUserItem[], boolean, string]> {
  try {
    console.log("Userliste");
    const docu = doc(firestore, 'company', companyId);
    const docComp = await getDoc(docu)
    const data = docComp.data();
    const compUsers = data?.user;
    const users: IUserItem[] = [];

    // Use Promise.all to wait for all user data fetching operations to complete
    await Promise.all(
      compUsers.map(async (compUser: any) => {
        const docum = doc(firestore, 'users', compUser.uid);
        const docUser = await getDoc(docum)
        const userItem = {
          id: docUser?.data()?.uid,
          displayName: docUser?.data()?.displayName,
          role: compUser.role,
          email: docUser?.data()?.email,
          photoURL: docUser?.data()?.photo,
          emailVerified: true,
        };
        users.push(userItem);
      })
    );
    return [users, false, ''];
  } catch (err) {
    console.error(err);
    return [[], false, err];
  }
}
