import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MATOMO_URL } from './config-global';
//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const instance = createInstance({
  urlBase: MATOMO_URL || '',
  siteId: 1,
})

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <MatomoProvider value={instance}>
          <App />
        </MatomoProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
