import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
// types
import { IInvoice, IResponse } from 'src/types/payment'
import { STRIPE_CLIENT_KEY } from '../config-global';
// ----------------------------------------------------------------------

type GetInvoices = {
  invoices: IInvoice[];
  invoicesLoading: boolean;
  invoicesError: string;
  invoicesEmpty: boolean;
};

type GetResponse = {
  response: IResponse;
  responseLoading: boolean;
  responseError: string;
  responseEmpty: boolean;
};


export function useGetInvoices(customerId: string) {
  const [memoizedValue, setMemoizedValue] = useState<GetInvoices>({
    invoices: [],
    invoicesLoading: true,
    invoicesError: '',
    invoicesEmpty: false,
  });
  useEffect(() => {
    if(customerId){
      getStripeInvoices(customerId).then(([invoices, isLoading, error]) => {
        setMemoizedValue({
          invoices: (invoices as IInvoice[]) || [],
          invoicesLoading: isLoading,
          invoicesError: error,
          invoicesEmpty: !isLoading && !invoices.length,
        });
      });
    }
  }, [customerId]);

  return memoizedValue;
}

function getStripeInvoices(customerId: string): Promise<[IInvoice[], boolean, string]> {
  return new Promise<[IInvoice[], boolean, string]>((resolve, reject) => {
    // Set your Stripe secret key
    const stripeSecretKey = STRIPE_CLIENT_KEY;

    // Define the Stripe API endpoint URL for invoice history
    const url: string = `https://api.stripe.com/v1/invoices?customer=${customerId}`;

    // Set up the HTTP headers with your Stripe secret key
    const headers: Record<string, string> = {
      'Authorization': `Bearer ${stripeSecretKey}`,
    };

    const invoices: IInvoice[] = [];
    let isLoading: boolean = false;
    let error: string = '';

    axios.get(url, { headers })
      .then((response: AxiosResponse) => {
        isLoading = true;

        // Check if the request was successful (status code 200)
        if (response.status === 200) {
          const invoiceData = response.data;

          // Process and add invoice data to the array
          invoiceData.data.forEach((invoice: Record<string, any>) => {
            console.log('Invoice ID:', invoice.id);
            console.log('Amount Due:', invoice.amount_due);
            console.log('Status:', invoice.status);
            console.log('Billing Period:', invoice.period_start, 'to', invoice.period_end);
            const counter:number = 1;
            const date = new Date(invoice.period_start);
            const invoiceHist: IInvoice = {
              id: invoice.id,
              price: invoice.amount_due/100,
              createdAt: date,
              invoiceNumber: invoice.number,
              pdf: invoice.invoice_pdf,
            };
            invoices.push(invoiceHist);
          });

          isLoading = false;
          resolve([invoices, isLoading, error]);
        } else {
          console.error(`Error: ${response.status} - ${response.statusText}`);
          error = `Error: ${response.status} - ${response.statusText}`;
          isLoading = false;
        }
      })
      .catch((err) => {
        console.error('Error:', err.message);
        error = err.message;
        isLoading = false;
      });
  });
}

export function useGetResponse(sessionId: string) {
  const [memoizedValue, setMemoizedValue] = useState<GetResponse>({
    response: {
      customerId: "",
      amount: 0,
    },
    responseLoading: true,
    responseError: '',
    responseEmpty: false,
  });
  useEffect(() => {
    if(sessionId){
      getStripeResponse(sessionId).then(([response, isLoading, error]) => {
        setMemoizedValue({
          response: (response as IResponse) || '',
          responseLoading: isLoading,
          responseError: error,
          responseEmpty: !isLoading,
        });
      });
    }
  }, [sessionId]);

  return memoizedValue;
}

function getStripeResponse(sessionId: string): Promise<[IResponse, boolean, string]> {
  return new Promise<[IResponse, boolean, string]>((resolve, reject) => {
    // Set your Stripe secret key
    const stripeSecretKey = STRIPE_CLIENT_KEY;

    // Define the Stripe API endpoint URL for invoice history
    const url: string = `https://api.stripe.com/v1/checkout/sessions/${sessionId}`;

    // Set up the HTTP headers with your Stripe secret key
    const headers: Record<string, string> = {
      'Authorization': `Bearer ${stripeSecretKey}`,
    };



    const responseData: IResponse = {
      customerId: '',
      amount: 0
    };
    let isLoading: boolean = false;
    let error: string = '';

    axios.get(url, { headers })
      .then((response: AxiosResponse) => {
        isLoading = true;

        // Check if the request was successful (status code 200)
        if (response.status === 200) {
          const sessionData = response.data;
          console.log(sessionData);
          // Extract the customer ID from the Checkout Session
          responseData.customerId = sessionData.customer;
          responseData.amount = sessionData.amount_total;
          isLoading = false;
          resolve([responseData, isLoading, error]);
        } else {
          console.error(`Error: ${response.status} - ${response.statusText}`);
          error = `Error: ${response.status} - ${response.statusText}`;
          isLoading = false;
        }
      })
      .catch((err) => {
        console.error('Error:', err.message);
        error = err.message;
        isLoading = false;
      });
  });
}

// ----------------------------------------------------------------------

