import firebase from 'firebase/compat/app';
import { query, collection, where, documentId, doc, setDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { AuthUserType } from 'src/auth/types';
import {
  firestore,
  getFBRole,
  getFBUsersComp,
} from 'src/firebase/firebaseInit';
// types
import { ICompany } from 'src/types/company';
import { IUserItem } from 'src/types/user';
// ----------------------------------------------------------------------

type GetCompany = {
  companys: ICompany[];
  companysLoading: boolean;
  companysError: string;
  companysEmpty: boolean;
};

type GetCompanyId = {
  company: ICompany;
  companyLoading: boolean;
  companyError: string;
  companyEmpty: boolean;
};

type GetUsers = {
  users: IUserItem[];
  usersLoading: boolean;
  usersError: string;
  usersEmpty: boolean;
};

function getCompByUid(
  users: { role: string; uid: string }[],
  targetUid: string
): boolean | undefined {
  return users.some((user) => user.uid === targetUid);
}

export function useGetCompanys(open: boolean, user: AuthUserType) {
  const [memoizedValue, setMemoizedValue] = useState<GetCompany>({
    companys: [],
    companysLoading: true,
    companysError: '',
    companysEmpty: false,
  });
  const [values, loading, error] = useCollectionData(open ? query(collection(firestore, 'company'), where('id', 'in', user?.companys)) : null);
  useEffect(() => {
    // const user = firebase.auth().currentUser;
    const companys: ICompany[] = [];
    values?.forEach((company) => {
      if (company) {
        const uid = user?.uid || '';
        const found = getCompByUid(company.user, uid);
        if (found) {
          companys.push(company as ICompany);
        }
      }
    });
    setMemoizedValue({
      companys: (companys as ICompany[]) || [],
      companysLoading: loading,
      companysError: error?.message || '',
      companysEmpty: !loading && !companys?.length,
    });
  }, [values, loading, error, user]);

  return memoizedValue;
}

export function useGetCompanyId(companyId: string) {
  const [memoizedValue, setMemoizedValue] = useState<GetCompanyId>({
    company: {
      id: '',
      name: '',
      street: '',
      housenumber: '',
      city: '',
      plz: '',
      country: '',
      state: '',
      abo: '',
      size: '',
      branche: '',
      user: [],
      userAvail: 0,
      numberRange: 0,
      productId: '',
      createdAt: '',
      disabled: '',
      lastScan: '',
    },
    companyLoading: true,
    companyError: '',
    companyEmpty: false,
  });

  const [values, loading, error] = useCollectionData(
    companyId ? query(collection(firestore, 'company'), where(documentId(), '==', companyId)) : null
  );

  useEffect(() => {
    setMemoizedValue({
      company: (values?.[0] as ICompany) || {},
      companyLoading: loading,
      companyError: error?.message || '',
      companyEmpty: !loading && !values?.length,
    });
  }, [values, loading, error]);

  return memoizedValue;
}

export function useGetUserRole(userId: string, companyId: string) {
  const [memoizedValue, setMemoizedValue] = useState<{
    role: string;
    roleLoading: boolean;
    roleError: string;
    roleEmpty: boolean;
  }>({
    role: '',
    roleLoading: true,
    roleError: '',
    roleEmpty: false,
  });
  useEffect(() => {
    if (companyId) {
      getFBRole(userId, companyId).then(([data, isLoading, error]) => {
        setMemoizedValue({
          role: data,
          roleLoading: isLoading,
          roleError: error,
          roleEmpty: !isLoading && !data.length,
        });
      });
    }
  }, [userId, companyId]);

  return memoizedValue;
}

export function useGetUsersComp(companyId: string) {
  const [memoizedValue, setMemoizedValue] = useState<GetUsers>({
    users: [],
    usersLoading: true,
    usersError: '',
    usersEmpty: false,
  });

  useEffect(() => {
    getFBUsersComp(companyId).then(([users, isLoading, error]) => {
      setMemoizedValue({
        users: (users as IUserItem[]) || [],
        usersLoading: isLoading,
        usersError: error,
        usersEmpty: !isLoading && !users.length,
      });
    });
  }, [companyId]);

  return memoizedValue;
}


export async function offlineCompany(company: ICompany): Promise<[string, boolean]> {
  const companyCol: ICompany = { ...company };
  const tmstmp = new Date().toISOString();
  companyCol.disabled = 'X';
  companyCol.lastScan = tmstmp;
  let loading = true;
  let error = '';
  try {
    // firestore
    //   .collection('company')
    //   .doc(company.id)
    //   .set(companyCol);
      const docu = doc(firestore, 'company', company.id);
      await setDoc(docu, companyCol)
  } catch (fbError) {
    error = fbError;
    console.log(error);
    loading = false;
  }
  return [error, loading];
}

export async function onlineCompany(company: ICompany): Promise<[string, boolean]> {
  const companyCol: ICompany = { ...company };
  companyCol.disabled = '';
  companyCol.lastScan = '';
  let loading = true;
  let error = '';
  try {
    // firestore
    //   .collection('company')
    //   .doc(company.id)
    //   .set(companyCol);
      const docu = doc(firestore, 'company', company.id);
      await setDoc(docu, companyCol)
  } catch (fbError) {
    error = fbError;
    console.log(error);
    loading = false;
  }
  return [error, loading];
}

// ----------------------------------------------------------------------
