// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
  dense?: boolean;
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
};

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  ...other
}: Props & TablePaginationProps) {
  const { t } = useLocales();

  return (
    <Box sx={{ display: 'flex', ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={t('productList.rowsPerPage')}
        component="div"
        {...other}
        sx={{
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      />

      {onChangeDense && (
        <FormControlLabel
          label={t('productList.dense')}
          control={<Switch checked={dense} onChange={onChangeDense} />}
        />
      )}
    </Box>
  );
}
