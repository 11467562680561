//
import { v4 as uuid } from 'uuid';
import { IProduct } from 'src/types/product';
import { fDate } from 'src/utils/format-time';
// ----------------------------------------------------------------------

export const _newproduct: IProduct = {
  id: uuid(),
  gtin: '',
  company: '',
  companyId: '',
  createdAt: '',
  createdBy: '',
  secondary: '',
  name: '',
  status: 'new',
  change: 'X',
  tags: [''],
  url: '',
  img: '',
  version: '1',
  publishedAt: fDate(new Date()),
  template: '',
  templateId: '',
  tempChangeAt: '',
  redirect: '',
  redirectURL: '',
  defaultLang: '',
  archived: '',
  archivedDate: '',
  changedBy: '',
  changedAt: '',
  qr: {
    id: '',
    quietZone: 5,
    ecLevel: 'M',
    bgColor: '#ffffff',
    fgColor: 'black',
    logoImage: '',
    logoPaddingStyle: 'square',
    logoOpacity: 1,
    removeQrCodeBehindLogo: true,
    qrStyle: 'dots',
    eyeRadius: [10, 10, 10],
    eyeColor: ['black', 'black', 'black'],
  },
  pass: {
    mode: '',
    version: '',
    releaseDate: '',
    widgets: [],
    design: {
      style: {
        general: {
          topButton: true,
        },
        buttons: {
          variant: '',
          size: '',
          textColor: '',
          buttonColor: '',
        },
        cards: {
          cardDesign: true,
          borderradius: 0,
          color: '',
        },
        icons: {
          showIcons: true,
          iconColor: '',
          showSubIcons: true,
          subIconColor: '',
        },
        colors: {
          primaryColor: '',
          secondaryColor: '',
          backgroundColor: '',
          generalTextColor: '',
          elementsColor: '',
        },
      },
      typography: {
        headlines: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        paragraphs: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        tags: {
          font: '',
          weight: '',
          color: '',
          tagColor: '',
        },
        table: {
          font: '',
          weight: '',
          color: '',
        },
      },
    },
  },
  live: {
    mode: '',
    version: '',
    releaseDate: '',
    widgets: [],
    design: {
      style: {
        general: {
          topButton: true,
        },
        buttons: {
          variant: '',
          size: '',
          textColor: '',
          buttonColor: '',
        },
        cards: {
          cardDesign: true,
          borderradius: 0,
          color: '',
        },
        icons: {
          showIcons: true,
          iconColor: '',
          showSubIcons: true,
          subIconColor: '',
        },
        colors: {
          primaryColor: '',
          secondaryColor: '',
          backgroundColor: '',
          generalTextColor: '',
          elementsColor: '',
        },
      },
      typography: {
        headlines: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        paragraphs: {
          font: '',
          weight: '',
          color: '',
          size: '',
        },
        tags: {
          font: '',
          weight: '',
          color: '',
          tagColor: '',
        },
        table: {
          font: '',
          weight: '',
          color: '',
        },
      },
    },
  },
};
