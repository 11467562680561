//
import { IWidget } from 'src/types/product';
import { v4 as uuid } from 'uuid';
// ----------------------------------------------------------------------

export const TextHeading: IWidget = {
  id: uuid(),
  name: 'TextHeading',
  order: 0,
  category: 'Text',
  open: false,
  label: {
    id: 'textheading',
    name: 'Heading',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Heading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Heading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Heading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Heading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

export const Subheading: IWidget = {
  id: uuid(),
  name: 'Subheading',
  order: 0,
  category: 'Text',
  open: false,
  label: {
    id: 'subheading',
    name: 'Subheading',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Subheading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Subheading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Subheading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Subheading',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

export const Textblock: IWidget = {
  id: uuid(),
  name: 'Textblock',
  order: 0,
  category: 'Text',
  open: false,
  label: {
    id: 'textblock',
    name: 'Textblock',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Text Block',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Text Block',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Text Block',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Text Block',
                value: ['Test'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Orientation',
                value: ['center'],
              },
          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

export const ActionButton: IWidget = {
  id: uuid(),
  name: 'ActionButton',
  order: 0,
  category: 'Action',
  open: false,
  label: {
    id: 'actionbutton',
    name: 'Button',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Name',
                value: ['Action'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Url',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Name',
                value: ['Action'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Url',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Name',
                value: ['Action'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Url',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Name',
                value: ['Action'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Url',
                value: [''],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

export const ShowcaseCarousel: IWidget = {
  id: uuid(),
  name: 'ShowcaseCarousel',
  order: 0,
  category: 'Showcase',
  open: false,
  label: {
    id: 'showcasecarousel',
    name: 'Carousel',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 1',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img1File',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 2',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img2File',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 3',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img3File',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 4',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img4File',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 1',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img1File',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 2',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img2File',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 3',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img3File',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 4',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img4File',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 1',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img1File',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 2',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img2File',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 3',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img3File',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 4',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img4File',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 1',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img1File',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 2',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img2File',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 3',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img3File',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image 4',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Img4File',
                value: [''],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};
export const ShowcaseImage: IWidget = {
  id: uuid(),
  name: 'ShowcaseImage',
  order: 0,
  category: 'Showcase',
  open: false,
  label: {
    id: 'showcaseimage',
    name: 'Image',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'ImgFile',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'ImgFile',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'ImgFile',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Ratio',
                value: ['1/1'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Image',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'ImgFile',
                value: [''],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};
export const ShowcaseVideo: IWidget = {
  id: uuid(),
  name: 'ShowcaseVideo',
  order: 0,
  category: 'Showcase',
  open: false,
  label: {
    id: 'showcasevideo',
    name: 'Video',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Youtube Embed ID',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Youtube Embed ID',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Youtube Embed ID',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: false,
                name: 'Youtube Embed ID',
                value: [''],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};
export const Document: IWidget = {
  id: uuid(),
  name: 'Document',
  order: 0,
  category: 'Data',
  open: false,
  label: {
    id: 'document',
    name: 'Document',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Document Name',
                value: [''],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Document Name',
                value: [''],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Document Name',
                value: [''],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Document Name',
                value: [''],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: '',
                value: [''],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

export const _components: IWidget[] = [
  TextHeading,
  Subheading,
  Textblock,
  ActionButton,
  ShowcaseCarousel,
  ShowcaseImage,
  ShowcaseVideo,
  Document,
];
