import { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function LabelGuard({ children }: Props) {
  // const router = useRouter();

  // const searchParams = useSearchParams();

  // const { authenticated } = useAuthContext();

  // const check = useCallback(() => {
  //   if (authenticated) {

  //   }
  // }, [authenticated, router]);

  // useEffect(() => {
  //   check();
  // }, [check]);

  return <>{children}</>;
}
