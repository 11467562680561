import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { firestore } from 'src/firebase/firebaseInit';
import { useAuthContext } from 'src/auth/hooks';
import { useGetCompanyId } from 'src/api/company';
import { useLocales } from 'src/locales';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { countries } from 'src/assets/data';
import { CardHeader, Divider } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';

// ----------------------------------------------------------------------

export default function AccountLegal() {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();

  const { company, companyLoading } = useGetCompanyId(user?.company);

  const UpdateCompanySchema = Yup.object().shape({
    companyName: Yup.string().required('Name is required'),
    abo: Yup.string().required('Name is required'),
    branche: Yup.string().required('Name is required'),
    city: Yup.string().required('Name is required'),
    state: Yup.string().required('Name is required'),
    country: Yup.string().required('Name is required'),
    plz: Yup.string().required('Name is required'),
    street: Yup.string().required('Name is required'),
    housenumber: Yup.string().required('Name is required'),
    size: Yup.string().required('Name is required'),
  });

  const methods = useForm({
    resolver: yupResolver(UpdateCompanySchema),
    defaultValues: {
      companyName: '',
      abo: '',
      branche: '',
      city: '',
      state: '',
      country: '',
      plz: '',
      street: '',
      housenumber: '',
      size: '',
    },
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      await new Promise((resolve) => setTimeout(resolve, 500));
      const companyItem = company;
      companyItem.name = data.companyName;
      companyItem.abo = data.abo;
      companyItem.branche = data.branche;
      companyItem.city = data.city;
      companyItem.state = data.state;
      companyItem.country = data.country;
      companyItem.plz = data.plz;
      companyItem.street = data.street;
      companyItem.housenumber = data.housenumber;
      companyItem.size = data.size;
      // firestore
      //   .collection('company')
      //   .doc(company.id)
      //   .set(companyItem)
      const docu = doc(firestore, 'company', company.id);
      setDoc(docu, companyItem).then(() => {
        enqueueSnackbar('Update success!');
      });
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    // Check if company data is available and not loading
    if (!companyLoading && company) {
      // Set the form values with the company data
      setValue('companyName', company.name || '');
      setValue('abo', company.abo || '');
      setValue('branche', company.branche || '');
      setValue('city', company.city || '');
      setValue('state', company.state || '');
      setValue('country', company.country || '');
      setValue('plz', company.plz || '');
      setValue('street', company.street || '');
      setValue('housenumber', company.housenumber || '');
      setValue('size', company.size || '');
    }
  }, [company, companyLoading, setValue]);

  return (
    <div>
      {!companyLoading && (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Card sx={{ pb: 3 }}>
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.contactInformation')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="phone" label={t('mngmt.companyaccount.phone')} />

                <RHFTextField name="phone" label={t('mngmt.companyaccount.email')} />
              </Box>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', py: 1 }} />
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.authorizedRepresentative')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="phone" label={t('mngmt.companyaccount.nameRepresentative')} />

                <RHFTextField name="phone" label={t('mngmt.companyaccount.titleRepresentative')} />
              </Box>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', py: 1 }} />
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.commercialRegisterInformation')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField
                  name="phone"
                  label={t('mngmt.companyaccount.commercialRegisterNumber')}
                />

                <RHFTextField
                  name="phone"
                  label={t('mngmt.companyaccount.competentRegistryCourt')}
                />
              </Box>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', py: 1 }} />
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.vatInformation')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="phone" label={t('mngmt.companyaccount.vatId')} />
              </Box>
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3, pr: 3 }}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={isSubmitting}
              >
                {t('general.saveButton')}
              </LoadingButton>
            </Stack>
          </Card>
        </FormProvider>
      )}
    </div>
  );
}
