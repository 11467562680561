import React, { forwardRef } from 'react';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
import { RouterLink } from 'src/routes/components';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  Icon?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, Icon = false, sx, ...other }, ref) => {
    let logoSvg = (
      <Box
        component="img"
        src="/logo/logo_single.svg"
        sx={{ width: 100, height: 40, cursor: 'pointer', ...sx }}
      />
    );

    if (Icon) {
      logoSvg = (
        <Box
          component="img"
          src="/logo/logo-icon.svg"
          sx={{ width: 64, height: 32, cursor: 'pointer', mt: 3 , ...sx }}
        />
      );
    }

    if (disabledLink) {
      return logoSvg;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logoSvg}
      </Link>
    );
  }
);

export default Logo;
