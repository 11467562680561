import { memo } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

function LogoNormalSlogan({ ...other }: BoxProps) {
  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 300 100"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M70.7495 48.4985H63.5732C62.4528 48.4985 61.7231 48.6154 61.3864 48.846C61.0493 49.0777 60.782 49.647 60.5842 50.5541L56.3009 74.4876C56.1031 75.3947 56.1224 75.9639 56.3581 76.1957C56.5942 76.4274 57.2729 76.5432 58.3933 76.5432H65.5696C66.69 76.5432 67.4193 76.4274 67.7564 76.1957C68.0935 75.9639 68.3608 75.3947 68.5586 74.4876L72.8419 50.5541C73.0397 49.647 73.0204 49.0777 72.7847 48.846C72.5486 48.6154 71.8699 48.4985 70.7495 48.4985Z"
          fill="#212B36"
        />
        <path
          d="M171.771 48.4985H164.595C163.474 48.4985 162.745 48.6154 162.408 48.846C162.071 49.0777 161.804 49.647 161.606 50.5541L157.323 74.4876C157.125 75.3947 157.144 75.9639 157.38 76.1957C157.616 76.4274 158.295 76.5432 159.415 76.5432H166.591C167.712 76.5432 168.441 76.4274 168.778 76.1957C169.115 75.9639 169.383 75.3947 169.58 74.4876L173.864 50.5541C174.061 49.647 174.042 49.0777 173.806 48.846C173.57 48.6154 172.892 48.4985 171.771 48.4985Z"
          fill="#212B36"
        />
        <path
          d="M102.988 41.4263C100.375 41.4263 98.0928 41.9064 96.1452 42.8639C94.1958 43.8228 92.3309 45.3972 90.5509 47.5876L91.1039 44.4512C91.3016 43.3304 91.2823 42.627 91.0468 42.3407C90.8109 42.0557 90.1327 41.9113 89.0119 41.9113H81.8433C80.7238 41.9113 79.9935 42.0557 79.6584 42.3407C79.3216 42.627 79.0545 43.3304 78.8569 44.4512L73.6425 74.0234C73.4448 75.1442 73.4641 75.8476 73.6996 76.1339C73.9341 76.4203 74.6136 76.5633 75.7331 76.5633H82.9017C84.0225 76.5633 84.7512 76.4203 85.088 76.1339C85.4248 75.8476 85.6918 75.1442 85.8895 74.0234L88.0555 61.7392C88.3804 59.8974 88.8163 58.4104 89.3652 57.2776C89.9126 56.1449 90.6851 55.1551 91.6808 54.3084C93.526 52.741 95.5809 51.956 97.8453 51.956C98.5677 51.956 99.7421 52.1431 101.37 52.5164C101.608 52.5672 101.817 52.5914 101.99 52.5914C102.513 52.5914 102.913 52.4042 103.191 52.031C103.468 51.6577 103.69 50.9985 103.857 50.0515L104.924 44.0037C105.121 42.8829 105.11 42.1742 104.888 41.8745C104.667 41.5763 104.034 41.4263 102.988 41.4263Z"
          fill="#212B36"
        />
        <path
          d="M132.982 41.3518C131.5 41.0042 130 40.8303 128.482 40.8303C123.329 40.8303 118.696 42.3977 114.584 45.5341C109.815 49.144 106.926 53.8116 105.916 59.537C104.722 66.3086 106.769 71.436 112.053 74.9201C114.86 76.7871 118.069 77.7208 121.679 77.7208C123.098 77.7208 124.679 77.535 126.426 77.1604C128.172 76.7871 129.37 76.3767 130.022 75.9284C130.282 75.729 130.462 75.5177 130.563 75.2944C130.665 75.0698 130.786 74.5602 130.927 73.7632L131.737 69.1705C132.035 67.4774 131.711 66.6306 130.766 66.6306C130.566 66.6306 130.243 66.7056 129.792 66.8553C128.327 67.4038 126.947 67.6765 125.653 67.6765C123.337 67.6765 121.482 66.9047 120.086 65.3612C118.712 63.8433 118.242 61.8396 118.682 59.3495C119.085 57.0598 120.092 55.1673 121.699 53.6746C123.662 51.857 125.975 50.9489 128.64 50.9489C129.81 50.9489 131.09 51.2472 132.477 51.8451C132.824 51.9937 133.11 52.0683 133.333 52.0683C133.906 52.0683 134.362 51.8076 134.705 51.2847C134.877 51.0106 135.044 50.4141 135.207 49.4923L136.043 44.7497C136.249 43.5795 136.202 42.8585 135.902 42.5845C135.438 42.111 134.465 41.7006 132.983 41.3515L132.982 41.3518Z"
          fill="#212B36"
        />
        <path
          d="M156.287 24.588H149.119C147.999 24.588 147.269 24.731 146.934 25.0173C146.597 25.3037 146.33 26.0071 146.133 27.1265L137.863 74.0237C137.666 75.1446 137.685 75.848 137.921 76.1343C138.155 76.4207 138.835 76.5637 139.954 76.5637H147.123C148.243 76.5637 148.972 76.4207 149.309 76.1343C149.646 75.848 149.913 75.1446 150.11 74.0237L158.38 27.1265C158.577 26.0071 158.558 25.3037 158.322 25.0173C158.086 24.731 157.408 24.588 156.287 24.588Z"
          fill="#212B36"
        />
        <path
          d="M207.47 24.2151C206.545 23.9662 205.533 23.8418 204.437 23.8418C199.932 23.8418 195.758 25.5591 191.916 28.9934C190.2 30.5383 188.905 32.167 188.029 33.8857C187.154 35.6031 186.462 37.9061 185.953 40.7936L185.756 41.9117H183.926C182.805 41.9117 182.076 42.0561 181.741 42.341C181.403 42.6273 181.136 43.3307 180.938 44.4516L180.036 49.5671C179.839 50.6865 179.858 51.3899 180.095 51.6763C180.329 51.9626 181.008 52.1056 182.128 52.1056H183.958L180.093 74.0233C179.896 75.1442 179.915 75.8476 180.15 76.1339C180.386 76.4203 181.064 76.5632 182.184 76.5632H189.354C190.473 76.5632 191.202 76.4203 191.539 76.1339C191.876 75.8476 192.143 75.1442 192.34 74.0233L196.205 52.1056H202.292C203.413 52.1056 204.14 51.9626 204.477 51.6763C204.813 51.3899 205.08 50.6865 205.278 49.5671L206.18 44.4516C206.378 43.3307 206.358 42.6273 206.123 42.341C205.887 42.0561 205.21 41.9117 204.089 41.9117H198.003L198.248 40.5227C198.682 38.0578 198.916 37.5822 199.346 36.8347C199.696 36.2623 200.287 35.7394 201.116 35.2673C201.946 34.7938 202.71 34.5573 203.407 34.5573C203.756 34.5573 204.551 34.7069 205.793 35.0052C206.007 35.056 206.202 35.0802 206.377 35.0802C206.924 35.0802 207.337 34.893 207.614 34.5198C207.891 34.1465 208.113 33.4873 208.28 32.5417L209.228 27.164C209.413 26.1195 209.407 25.4466 209.21 25.1484C208.978 24.7741 208.398 24.4635 207.471 24.2147L207.47 24.2151Z"
          fill="#212B36"
        />
        <path
          d="M254.604 42.231C254.269 42.0196 253.627 41.9127 252.681 41.9127H244.206C243.209 41.9127 242.587 41.9821 242.34 42.1188C242.091 42.2551 241.664 42.7714 241.058 43.6676L230.094 59.6875L224.561 43.6301C224.267 42.7595 224.02 42.2551 223.819 42.1188C223.619 41.9825 223.021 41.9127 222.026 41.9127H213.363C212.416 41.9127 211.738 42.0196 211.328 42.231C210.917 42.4423 210.665 42.8089 210.573 43.3318C210.511 43.6809 210.645 44.2655 210.974 45.0866L221.195 71.822L207.775 90.7534C207.182 91.5745 206.858 92.1468 206.801 92.4707C206.7 93.043 206.888 93.4538 207.367 93.7026C207.62 93.827 208.193 93.8898 209.089 93.8898H217.341C218.312 93.8898 218.927 93.8214 219.188 93.6837C219.449 93.5474 219.88 93.043 220.482 92.1724L254.032 45.0491C254.595 44.2532 254.909 43.6809 254.97 43.3318C255.062 42.8089 254.94 42.4423 254.604 42.231Z"
          fill="#212B36"
        />
        <path
          d="M172.049 24.6063C173.965 24.6063 175.478 25.2778 176.585 26.6219C177.692 27.966 178.079 29.5845 177.745 31.4767C177.407 33.3931 176.448 35.0235 174.867 36.3676C173.286 37.7117 171.549 38.3846 169.657 38.3846C167.716 38.3846 166.203 37.712 165.121 36.3676C164.018 34.9993 163.638 33.3437 163.98 31.4017C164.305 29.5599 165.264 27.966 166.858 26.6215C168.452 25.2775 170.182 24.6063 172.049 24.6063Z"
          fill="#212B36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.2811 3.00801C31.2811 3.00801 42.1959 -1.47817 56.7703 0.506816C56.7703 0.506816 69.2366 7.3233 74.792 30.4838L78.3263 29.6741C77.0178 32.1099 74.207 36.3722 71.4265 39.8526C68.6635 43.6996 67.0634 44.7551 62.6157 42.6759C50.2025 36.873 62.6157 42.6759 50.2025 36.873C50.2028 36.8729 53.8726 35.8711 53.8726 35.8711C53.8726 35.8711 53.0195 16.0796 31.2811 3.00801Z"
          fill="url(#paint0_linear_3059_60630)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.0545 19.8511C29.272 27.1089 21.7874 35.5086 18.9893 53.0326C12.3794 50.9694 2.46088 43.209 0.582301 39.6854C3.05727 29.0749 9.2135 12.0897 28.2682 4.44556C31.6002 5.87952 40.8785 12.6434 45.0545 19.8511Z"
          fill="#212B36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 43.3216C14.2652 58.9166 35.1682 60.6881 51.5483 55.3783C52.2556 55.1491 54.8983 54.2646 55.1045 55.8608C54.4881 61.84 52.7059 70.2668 50.426 74.6851C49.9566 75.5947 49.2717 75.6948 48.2184 75.9291C27.9606 80.4367 12.3884 75.8677 4.44269 68.2932C0.928621 59.5339 0.282407 50.7654 0 43.3216Z"
          fill="url(#paint1_linear_3059_60630)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3059_60630"
            x1="58.6092"
            y1="-13.7556"
            x2="-0.000136921"
            y2="104.305"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAB00" />
            <stop offset="0.136705" stopColor="#80AB2B" />
            <stop offset="0.3" stopColor="#00AB55" />
            <stop offset="0.465196" stopColor="#00AB55" />
            <stop offset="0.902096" stopColor="#00B8D9" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3059_60630"
            x1="64.7228"
            y1="-10.574"
            x2="-7.92284"
            y2="100.905"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0187618" stopColor="#FFAB00" />
            <stop offset="0.115031" stopColor="#80AB2B" />
            <stop offset="0.225978" stopColor="#00AB55" />
            <stop offset="0.465196" stopColor="#00AB55" />
            <stop offset="0.902096" stopColor="#00B8D9" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default memo(LogoNormalSlogan);
