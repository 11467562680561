// ----------------------------------------------------------------------

export const _nutriReference = [
  {
    key: 'Energy',
    value: 8400,
  },
  {
    key: 'Fat',
    value: 70,
  },
  {
    key: 'Saturated Fat',
    value: 20,
  },
  {
    key: 'Unsaturated Fat',
    value: 50,
  },
  {
    key: 'Carbohydrates',
    value: 260,
  },
  {
    key: 'Sugar',
    value: 90,
  },
  {
    key: 'Starch',
    value: 310,
  },
  {
    key: 'Polyhydric Alcohols',
    value: 20,
  },
  {
    key: 'Dietary Fiber',
    value: 100,
  },
  {
    key: 'Protein',
    value: 50,
  },
  {
    key: 'Salt',
    value: 6,
  },
];
