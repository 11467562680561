import { useEffect, useReducer, useCallback, useMemo } from 'react';
//
import { ICompany, IUsers } from 'src/types/company';
//
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, query, where, documentId, getDocs, addDoc } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// config
import { FIREBASE_API, FIREBASE_DATABASE_ID } from 'src/config-global';
import { firestore } from '../../../firebase/firebaseInit';
//
import { AuthContext } from './auth-context';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';


// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp, `${FIREBASE_DATABASE_ID}`);

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

type CompanyPayload = {
  collection: string,
  colData: ICompany,
}

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------


type Props = {
  children: React.ReactNode;
};

function generateRandomId(): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 5; i += 1) {
      id += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return id;
}

const newCompany1 = async (newUser: UserCredential, email: string, firstName: string, lastName: string, company: string | undefined, abo: string | undefined) => {
  const now = new Date();
  const productId = generateRandomId();
  const colData = {
    abo,
    name: company,
    branche: "",
    city: "",
    country: "",
    state: "",
    plz: "",
    street: "",
    housenumber: "",
    size: "",
    userAvail: 0,
    numberRange: 0,
    productId,
    user: [
      {
        uid: newUser.user.uid,
        role: 'admin',
      }
    ],
    id: "",
    createdAt: now,
  };
  const payload = {
    collection: "company",
    colData,
  }
  // await firestore.collection(payload.collection).add(payload.colData).then((docRef) => {
  //   colData.id = docRef.id;
  // });
  const colRef = collection(firestore, payload.collection);
  const docRef = await addDoc(colRef, payload.colData);
  colData.id = docRef.id;
  // await firestore.collection(payload.collection).doc(colData.id).set(payload.colData);
  const docu = doc(firestore, payload.collection, colData.id);
  await setDoc(docu, payload.colData);
  const userProfileNew = {
    uid: newUser.user?.uid,
    email,
    displayName: `${firstName} ${lastName}`,
    photo: '',
    company: colData.id,
    companys: [colData.id],
  }
  // await firestore.collection('users').doc(newUser.user?.uid).set(userProfileNew);
  const docum = doc(firestore, 'users', newUser.user?.uid);
  await setDoc(docum, userProfileNew);
};

const updateCompany1 = async (newUser: UserCredential, email: string, firstName: string, lastName: string, company: string | undefined, role: string | undefined) => {
  const companyQuery = query(
    collection(firestore, 'company'),
    where('name', '==', company)
  );
  const querySnapshot = await getDocs(companyQuery);
  const updateCompany = querySnapshot.docs.map((document) => document.data() as ICompany);
  const payload:CompanyPayload = {
    collection: "company",
    colData: updateCompany[0],
  }
  if(role){
    const addUser:IUsers = {
      uid: newUser.user.uid,
      role,
    }
    payload.colData.user.push(addUser);
    // await firestore.collection(payload.collection).doc(payload.colData.id).set(payload.colData);
    const docu = doc(firestore, payload.collection, payload.colData.id);
    await setDoc(docu, payload.colData);
    const userProfileNew = {
      uid: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
      photo: '',
      company: payload.colData.id,
      companys: [payload.colData.id],
    }
    // await firestore.collection('users').doc(newUser.user?.uid).set(userProfileNew);
    const docum = doc(firestore, 'users', newUser.user?.uid);
    await setDoc(docum, userProfileNew);
  }
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          if (user.emailVerified) {
            const userProfile = doc(DB, 'users', user.uid);

            const docSnap = await getDoc(userProfile);

            const profile = docSnap.data();

            dispatch({
              type: Types.INITIAL,
              payload: {
                user: {
                  ...user,
                  ...profile,
                  id: user.uid,
                },
              },
            });
          } else {
            dispatch({
              type: Types.INITIAL,
              payload: {
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string,     
      company1: string | undefined, 
      checkCompany1: boolean, 
      company1abo: string | undefined, 
      company1role: string | undefined, 
      company2: string | undefined, 
      checkCompany2: boolean, 
      company2abo: string | undefined, 
      company2role: string | undefined) => {
      const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

      await sendEmailVerification(newUser.user);

      const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);

      await setDoc(userProfile, {
        uid: newUser.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        photo: '',
        company: '',
      }).then(async () => {
        const user = firebase.auth().currentUser;
        if (user) {
          if(checkCompany1){
            newCompany1(newUser, email, firstName, lastName, company1, company1abo);
          } else {
            updateCompany1(newUser, email, firstName, lastName, company1, company1role);
          }
        }
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  const updateUser = useCallback(
    async (user: AuthUserType) => {
      try {
        if (user) {
          if (user.emailVerified) {
            const userProfile = doc(DB, 'users', user.uid);

            const docSnap = await getDoc(userProfile);

            const profile = docSnap.data();

            dispatch({
              type: Types.INITIAL,
              payload: {
                user: {
                  ...user,
                  ...profile,
                  id: user.uid,
                },
              },
            });
          } else {
            dispatch({
              type: Types.INITIAL,
              payload: {
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }
      } catch (error) {
        console.error(error);
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    },
    [] // Add any dependencies if needed
  );
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      updateUser,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      updateUser,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
