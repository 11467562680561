import { format, getTime, formatDistanceToNow, isValid } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  if (date !== undefined && date !== null) {
    const parsedDate = new Date(date);

    if (isValid(parsedDate)) {
      return format(parsedDate, fm);
    }
  }
  return '';
}

export function fDateMonth(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy-MM';

  if (date !== undefined && date !== null) {
    const parsedDate = new Date(date);

    if (isValid(parsedDate)) {
      return format(parsedDate, fm);
    }
  }
  return '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
