import { Helmet } from 'react-helmet-async';
import { UserListView } from 'src/sections/management/view';

// ----------------------------------------------------------------------

export default function UsersListPage() {
  return (
    <>
      <Helmet>
        <title>Circlify: Users List</title>
      </Helmet>
      <UserListView />
    </>
  );
}
