
// ----------------------------------------------------------------------

export const _paymentPlans = [
  {
    subscription: 'basic',
    price: 69,
    primary: false,
    scans: 20000,
    products: 10,
    analytics: false,
  },
  {
    subscription: 'starter',
    price: 225,
    primary: false,
    scans: 30000,
    products: 50,
    analytics: false,
  },
  {
    subscription: 'premium',
    price: 600,
    primary: false,
    scans: 40000,
    products: 100,
    analytics: false,
  },
];
