import { memo } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

function LogoBigSlogan({ ...other }: BoxProps) {
  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 3337 1229"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg
        width="3337"
        height="1229"
        viewBox="0 0 3337 1229"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M925.848 634.666H831.937C817.275 634.666 807.727 636.195 803.32 639.213C798.909 642.245 795.411 649.695 792.822 661.566L736.77 974.765C734.181 986.636 734.434 994.086 737.518 997.118C740.608 1000.15 749.49 1001.67 764.152 1001.67H858.063C872.725 1001.67 882.268 1000.15 886.68 997.118C891.091 994.086 894.589 986.636 897.178 974.765L953.23 661.566C955.819 649.695 955.566 642.245 952.482 639.213C949.392 636.195 940.51 634.666 925.848 634.666Z"
          fill="#212B36"
        />
        <path
          d="M2247.85 634.666H2153.94C2139.28 634.666 2129.73 636.195 2125.32 639.213C2120.91 642.245 2117.41 649.695 2114.82 661.566L2058.77 974.765C2056.18 986.636 2056.43 994.086 2059.52 997.118C2062.61 1000.15 2071.49 1001.67 2086.15 1001.67H2180.06C2194.72 1001.67 2204.27 1000.15 2208.68 997.118C2213.09 994.086 2216.59 986.636 2219.18 974.765L2275.23 661.566C2277.82 649.695 2277.57 642.245 2274.48 639.213C2271.39 636.195 2262.51 634.666 2247.85 634.666Z"
          fill="#212B36"
        />
        <path
          d="M1347.73 542.116C1313.53 542.116 1283.67 548.4 1258.18 560.93C1232.67 573.478 1208.27 594.081 1184.97 622.746L1192.21 581.702C1194.8 567.034 1194.55 557.829 1191.46 554.082C1188.38 550.353 1179.5 548.464 1164.83 548.464H1071.02C1056.37 548.464 1046.82 550.353 1042.43 554.082C1038.02 557.829 1034.53 567.034 1031.94 581.702L963.706 968.691C961.119 983.359 961.372 992.564 964.454 996.311C967.522 1000.06 976.415 1001.93 991.064 1001.93H1084.88C1099.54 1001.93 1109.08 1000.06 1113.49 996.311C1117.89 992.564 1121.39 983.359 1123.97 968.691L1152.32 807.937C1156.57 783.835 1162.28 764.375 1169.46 749.551C1176.62 734.728 1186.73 721.776 1199.76 710.695C1223.91 690.185 1250.8 679.911 1280.43 679.911C1289.88 679.911 1305.25 682.36 1326.55 687.245C1329.67 687.91 1332.4 688.226 1334.68 688.226C1341.52 688.226 1346.75 685.777 1350.39 680.893C1354.02 676.008 1356.92 667.381 1359.1 654.988L1373.06 575.845C1375.65 561.177 1375.49 551.904 1372.6 547.982C1369.7 544.079 1361.42 542.116 1347.73 542.116Z"
          fill="#212B36"
        />
        <path
          d="M1740.25 541.143C1720.85 536.593 1701.21 534.318 1681.35 534.318C1613.91 534.318 1553.29 554.829 1499.48 595.873C1437.07 643.113 1399.26 704.195 1386.05 779.119C1370.42 867.733 1397.2 934.833 1466.36 980.427C1503.1 1004.86 1545.08 1017.08 1592.32 1017.08C1610.89 1017.08 1631.58 1014.65 1654.45 1009.74C1677.3 1004.86 1692.98 999.488 1701.51 993.622C1704.9 991.012 1707.27 988.247 1708.59 985.325C1709.93 982.385 1711.51 975.716 1713.35 965.287L1723.95 905.186C1727.85 883.029 1723.61 871.948 1711.24 871.948C1708.63 871.948 1704.39 872.93 1698.5 874.888C1679.33 882.066 1661.27 885.634 1644.33 885.634C1614.03 885.634 1589.75 875.535 1571.48 855.336C1553.5 835.472 1547.35 809.252 1553.1 776.665C1558.39 746.702 1571.56 721.935 1592.59 702.402C1618.28 678.616 1648.55 666.733 1683.42 666.733C1698.73 666.733 1715.48 670.636 1733.63 678.46C1738.17 680.405 1741.92 681.382 1744.84 681.382C1752.33 681.382 1758.3 677.97 1762.78 671.127C1765.05 667.54 1767.22 659.734 1769.35 647.672L1780.3 585.608C1783 570.294 1782.38 560.86 1778.45 557.273C1772.38 551.077 1759.64 545.706 1740.25 541.138L1740.25 541.143Z"
          fill="#212B36"
        />
        <path
          d="M2045.22 321.766H1951.41C1936.76 321.766 1927.21 323.637 1922.82 327.384C1918.41 331.131 1914.91 340.336 1912.33 354.986L1804.12 968.696C1801.53 983.364 1801.78 992.569 1804.87 996.316C1807.94 1000.06 1816.83 1001.93 1831.48 1001.93H1925.29C1939.96 1001.93 1949.49 1000.06 1953.9 996.316C1958.31 992.569 1961.8 983.364 1964.39 968.696L2072.6 354.986C2075.18 340.336 2074.93 331.131 2071.85 327.384C2068.76 323.637 2059.89 321.766 2045.22 321.766Z"
          fill="#212B36"
        />
        <path
          d="M2715.02 316.885C2702.9 313.628 2689.66 312 2675.33 312C2616.36 312 2561.75 334.474 2511.47 379.416C2489.01 399.633 2472.06 420.946 2460.61 443.438C2449.15 465.912 2440.09 496.049 2433.43 533.837L2430.85 548.467H2406.91C2392.24 548.467 2382.7 550.357 2378.32 554.086C2373.9 557.833 2370.4 567.038 2367.81 581.705L2356 648.649C2353.42 663.298 2353.67 672.503 2356.77 676.25C2359.84 679.997 2368.72 681.868 2383.38 681.868H2407.33L2356.75 968.69C2354.16 983.358 2354.42 992.563 2357.5 996.31C2360.59 1000.06 2369.46 1001.93 2384.11 1001.93H2477.94C2492.59 1001.93 2502.12 1000.06 2506.53 996.31C2510.94 992.563 2514.43 983.358 2517.02 968.69L2567.59 681.868H2647.25C2661.91 681.868 2671.43 679.997 2675.84 676.25C2680.25 672.503 2683.74 663.298 2686.32 648.649L2698.13 581.705C2700.72 567.038 2700.46 557.833 2697.38 554.086C2694.29 550.357 2685.44 548.467 2670.77 548.467H2591.12L2594.32 530.291C2600.01 498.035 2603.07 491.811 2608.7 482.028C2613.28 474.539 2621.01 467.696 2631.86 461.518C2642.72 455.321 2652.72 452.226 2661.84 452.226C2666.4 452.226 2676.81 454.184 2693.06 458.087C2695.87 458.752 2698.42 459.069 2700.71 459.069C2707.87 459.069 2713.27 456.619 2716.9 451.735C2720.52 446.85 2723.43 438.223 2725.61 425.849L2738.02 355.475C2740.43 341.807 2740.36 333.001 2737.79 329.098C2734.75 324.2 2727.15 320.136 2715.02 316.88L2715.02 316.885Z"
          fill="#212B36"
        />
        <path
          d="M3331.83 552.646C3327.43 549.88 3319.03 548.482 3306.66 548.482H3195.75C3182.71 548.482 3174.57 549.39 3171.33 551.178C3168.07 552.963 3162.49 559.718 3154.56 571.446L3011.08 781.087L2938.67 570.955C2934.82 559.562 2931.59 552.963 2928.96 551.178C2926.34 549.394 2918.52 548.482 2905.49 548.482H2792.13C2779.74 548.482 2770.86 549.88 2765.49 552.646C2760.12 555.412 2756.83 560.209 2755.62 567.052C2754.81 571.62 2756.56 579.27 2760.87 590.016L2894.62 939.883L2719.01 1187.62C2711.25 1198.37 2707 1205.86 2706.26 1210.1C2704.93 1217.59 2707.4 1222.96 2713.67 1226.22C2716.97 1227.85 2724.47 1228.67 2736.2 1228.67H2844.18C2856.89 1228.67 2864.94 1227.77 2868.36 1225.97C2871.77 1224.19 2877.42 1217.59 2885.29 1206.19L3324.34 589.526C3331.7 579.11 3335.81 571.62 3336.61 567.052C3337.82 560.209 3336.22 555.412 3331.83 552.646Z"
          fill="#212B36"
        />
        <path
          d="M2251.48 322.005C2276.56 322.005 2296.36 330.792 2310.84 348.381C2325.33 365.97 2330.39 387.15 2326.03 411.912C2321.6 436.991 2309.05 458.327 2288.36 475.916C2267.67 493.505 2244.94 502.311 2220.18 502.311C2194.78 502.311 2174.99 493.51 2160.82 475.916C2146.38 458.011 2141.41 436.344 2145.89 410.931C2150.14 386.829 2162.69 365.97 2183.55 348.377C2204.41 330.788 2227.05 322.005 2251.48 322.005Z"
          fill="#212B36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M409.353 39.3637C409.353 39.3637 552.188 -19.3438 742.912 6.63234C742.912 6.63234 906.05 95.8348 978.749 398.92L1025 388.324C1007.88 420.199 971.094 475.977 934.708 521.523C898.55 571.865 877.611 585.679 819.407 558.469C656.964 482.531 819.407 558.469 656.964 482.531C656.968 482.529 704.992 469.419 704.992 469.419C704.992 469.419 693.829 210.422 409.353 39.3637Z"
          fill="url(#paint0_linear_2977_8915)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M589.596 259.777C383.061 354.755 285.117 464.675 248.5 694C162 667 32.2038 565.445 7.62016 519.334C40.0083 380.483 120.571 158.209 369.926 58.1759C413.53 76.941 534.948 165.455 589.596 259.777Z"
          fill="#212B36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 566.918C186.678 771 460.221 794.182 674.576 724.697C683.832 721.697 718.415 710.122 721.114 731.01C713.047 809.256 689.725 919.532 659.889 977.351C653.746 989.255 644.784 990.563 631 993.63C365.9 1052.62 162.118 992.826 58.1383 893.705C12.1522 779.078 3.69566 664.33 0 566.918Z"
          fill="url(#paint1_linear_2977_8915)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2977_8915"
            x1="766.977"
            y1="-180.009"
            x2="-0.00205134"
            y2="1364.97"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAB00" />
            <stop offset="0.136705" stopColor="#80AB2B" />
            <stop offset="0.3" stopColor="#00AB55" />
            <stop offset="0.465196" stopColor="#00AB55" />
            <stop offset="0.902096" stopColor="#00B8D9" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2977_8915"
            x1="846.981"
            y1="-138.375"
            x2="-103.681"
            y2="1320.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0187618" stopColor="#FFAB00" />
            <stop offset="0.115031" stopColor="#80AB2B" />
            <stop offset="0.225978" stopColor="#00AB55" />
            <stop offset="0.465196" stopColor="#00AB55" />
            <stop offset="0.902096" stopColor="#00B8D9" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default memo(LogoBigSlogan);
