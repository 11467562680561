import isEqual from 'lodash/isEqual';
import { useState, useCallback } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _roles } from 'src/_mock';
// hooks
import { useLocales } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { useGetCompanyId, useGetUsersComp } from 'src/api/company';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { Container, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
// types
import { IUserItem, IUserTableFilters, IUserTableFilterValue } from 'src/types/user';
// sections
import UserAppWidget from '../user-app-widget';
import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import UserNewForm from '../user-new-form';



// ----------------------------------------------------------------------

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
};

// ----------------------------------------------------------------------

export default function UserListView() {
  const { t } = useLocales();
  const TABLE_HEAD = [
    { id: '' },
    { id: 'displayName', label: t('mngmt.userlist.name') },
    { id: 'role', label: t('mngmt.userlist.role'), width: 180 },
    { id: 'email', label: t('mngmt.userlist.email'), width: 100 },
  ];
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  // const [tableData, setTableData] = useState(_userList);
  const { user } = useAuthContext();

  const { company } = useGetCompanyId(user?.company);

  const { users, usersLoading } = useGetUsersComp(user?.company);

  const tableData = users;

  const userAll = Number(company.userAvail);

  const userCreated = tableData.length;

  const userPercentage = Math.round(userCreated*100/userAll);

  const quickEdit = useBoolean();

  // const tableData = userArray;

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.user.account);
    },
    [router]
  );

  const handleDeleteRow = useCallback(
    (id: string) => {
      // const deleteRow = tableData.filter((row) => row?.id !== id);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table]
  );

  const handleDeleteRows = useCallback(() => {
    // const deleteRows = tableData.filter((row) => !table.selected.includes(row?.id));
    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('mngmt.userlist.title')}
          links={[{ name: 'Dashboard', href: paths.dashboard.root }, { name: t('mngmt.userlist.title') }]}
          action={
            <Button
              component={RouterLink}
              onClick={quickEdit.onTrue}
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('mngmt.userlist.newUser')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <UserAppWidget
        title={`${t('mngmt.userlist.userTotal')} ${userAll.toString()}`}
          total={userCreated}
          icon="solar:user-rounded-bold"
          chart={{
            series: userPercentage,
          }}
        />
        <Card>
          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            roleOptions={_roles}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}
          {usersLoading ? ( // Check if tableData is empty
            <div>Loading data...</div> // You can replace this with a loading indicator or message
          ) : (
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                action={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <UserTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          )}
          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content={
            <>
              {/* Are you sure want to delete <strong> {table.selected.length} </strong> items? */}
              <Card sx={{mt:2}}>
                <Typography variant="body2" sx={{mt:2}}>
                    {t('mngmt.userlist.deleteUserText')}
                </Typography>
                <Typography variant="body2">
                {t('mngmt.userlist.userText')}
                </Typography>
                <Typography variant="body2">{t('mngmt.userlist.company')}</Typography>
                <Typography variant="body2">{t('mngmt.userlist.firstname')}</Typography>
                <Typography variant="body2">{t('mngmt.userlist.lastname')}</Typography>
                <Typography variant="body2">{t('mngmt.userlist.email')}</Typography>
                <Typography variant="body2">{t('mngmt.userlist.operation')}</Typography>
              </Card>
            </>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRows();
                confirm.onFalse();
              }}
            >
              {t('general.deleteButton')}
            </Button>
          }
        />
      </Container>
      <UserNewForm open={quickEdit.value} onClose={quickEdit.onFalse} />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: IUserItem[];
  comparator: (a: any, b: any) => number;
  filters: IUserTableFilters;
}) {
  const { name, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user.displayName.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }

  return inputData;
}
