//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';

// ----------------------------------------------------------------------

const FooterWidget: IWidget = {
  id: uuid(),
  name: 'FooterWidget',
  order: 0,
  category: 'Required',
  open: false,
  label: {
    id: 'footer',
    name: 'Footer',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website URL',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Website URL',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'X URL',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show X URL',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'Instagram URL',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Instagram URL',
              value: ['X'],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Facebook URL',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Facebook URL',
              value: ['X'],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website Name',
              value: [''],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Imprint',
              value: [''],
            },
          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website URL',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Website URL',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'X URL',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show X URL',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'Instagram URL',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Instagram URL',
              value: ['X'],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Facebook URL',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Facebook URL',
              value: ['X'],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website Name',
              value: [''],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Imprint',
              value: [''],
            },
          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website URL',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Website URL',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'X URL',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show X URL',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'Instagram URL',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Instagram URL',
              value: ['X'],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Facebook URL',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Facebook URL',
              value: ['X'],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website Name',
              value: [''],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Imprint',
              value: [''],
            },
          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website URL',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Website URL',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'X URL',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show X URL',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'Instagram URL',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Instagram URL',
              value: ['X'],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Facebook URL',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Facebook URL',
              value: ['X'],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Website Name',
              value: [''],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Imprint',
              value: [''],
            },
          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['center'],
      },
      '2': {
        name: 'Show Rights Reserved',
        value: ['X'],
      },
      '3': {
        name: 'Logo',
        value: [''],
      },
      '4': {
        name: 'Show Logo',
        value: [''],
      },
      '5': {
        name: 'Logo Filename',
        value: [''],
      },
    },
  },
};

const Rebuy: IWidget = {
  id: uuid(),
  name: 'Rebuy',
  order: 0,
  category: '',
  open: false,
  label: {
    id: 'rebuy',
    name: 'Rebuy',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Go to top',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Contact Mail',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Contact Mail',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Rebuy',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'URL to Product in Shop',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code',
              value: [''],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code Description',
              value: [''],
            },
          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Go to top',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Contact Mail',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Contact Mail',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Rebuy',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'URL to Product in Shop',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code',
              value: [''],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code Description',
              value: [''],
            },
          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Go to top',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Contact Mail',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Contact Mail',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Rebuy',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'URL to Product in Shop',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code',
              value: [''],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code Description',
              value: [''],
            },
          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: false,
              name: 'Go to top',
              value: [''],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Contact Mail',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Contact Mail',
              value: [''],
            },
            '4': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Rebuy',
              value: ['X'],
            },
            '5': {
              feId: '',
              change: false,
              translate: false,
              name: 'URL to Product in Shop',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code',
              value: [''],
            },
            '7': {
              feId: '',
              change: false,
              translate: false,
              name: 'Discount Code Description',
              value: [''],
            },
          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['center'],
      },
    },
  },
};

const ProductHead: IWidget = {
  id: uuid(),
  name: 'ProductHead',
  order: 0,
  category: 'Required',
  open: true,
  label: {
    id: 'producthead',
    name: 'Product Head',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: true,
              translate: true,
              name: 'Brand Name',
              value: ['Brand Name'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Name',
              value: ['Product Name'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product Tags',
              value: ['X'],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Tags',
              value: ['Tag1', 'Tag2'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Picture',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Product Banner',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Filename',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Banner Filename',
              value: [''],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product',
              value: ['X'],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Banner',
              value: ['X'],
            },
          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: true,
              translate: true,
              name: 'Brand Name',
              value: ['Brand Name'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Name',
              value: ['Product Name'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product Tags',
              value: ['X'],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Tags',
              value: ['Tag1', 'Tag2'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Picture',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Product Banner',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Filename',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Banner Filename',
              value: [''],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product',
              value: ['X'],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Banner',
              value: ['X'],
            },
          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: true,
              translate: true,
              name: 'Brand Name',
              value: ['Brand Name'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Name',
              value: ['Product Name'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product Tags',
              value: ['X'],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Tags',
              value: ['Tag1', 'Tag2'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Picture',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Product Banner',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Filename',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Banner Filename',
              value: [''],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product',
              value: ['X'],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Banner',
              value: ['X'],
            },
          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: true,
              translate: true,
              name: 'Brand Name',
              value: ['Brand Name'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Name',
              value: ['Product Name'],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product Tags',
              value: ['X'],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Product Tags',
              value: ['Tag1', 'Tag2'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Picture',
              value: [''],
            },
            '6': {
              feId: '',
              change: false,
              translate: false,
              name: 'Product Banner',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Product Filename',
              value: [''],
            },
            '8': {
              feId: '',
              change: false,
              translate: false,
              name: 'Banner Filename',
              value: [''],
            },
            '9': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Product',
              value: ['X'],
            },
            '10': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Banner',
              value: ['X'],
            },
          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['left'],
      },
      '2': {
        name: 'Product Shape',
        value: ['round'],
      },
      '3': {
        name: 'Product Size',
        value: ['s'],
      },
      '4': {
        name: 'Banner Shape',
        value: ['square'],
      },
      '5': {
        name: 'Banner Size',
        value: ['21/9'],
      },
      '6': {
        name: 'Name Size',
        value: ['s'],
      },
      '7': {
        name: 'Name Color',
        value: [''],
      },
      '8': {
        name: 'Card Background',
        value: [''],
      },
    },
  },
};

const ProductInfo: IWidget = {
  id: uuid(),
  name: 'ProductInfo',
  order: 0,
  category: 'Required',
  open: false,
  label: {
    id: 'productinfo',
    name: 'Product Info',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Product Information'],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Widget Headline',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Description',
              value: ['Lorem ipsum'],
            },
            '4': {
              feId: 'basicInfo',
              change: true,
              translate: true,
              name: 'Basic Information',
              value: [
                { key: 'EAN', value: '435465464565' },
                { key: 'Amount', value: '700 ml' },
                { key: 'Type', value: 'Dry' },
                { key: 'Year', value: '2021' },
                { key: 'Alcohol Level', value: '13.5%' },
              ],
            },
          },
        },
        {
          id: 'Nutrients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Nutrients'],
            },
            '2': {
              feId: '',
              change: true,
              translate: false,
              name: 'Nutrients',
              value: [
                { key: 'Energy', value: 0 },
                {
                  key: 'Fat',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Saturated Fat',
                      value: 0,
                    },
                    {
                      key: 'Unsaturated Fat',
                      value: 0,
                    },
                  ],
                },
                {
                  key: 'Carbohydrates',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Sugar',
                      value: 0,
                    },
                    {
                      key: 'Polyhydric Alcohols',
                      value: 0,
                    },
                    {
                      key: 'Starch',
                      value: 0,
                    },
                  ],
                },
                { key: 'Dietary Fiber', value: 0 },
                { key: 'Protein', value: 0 },
                { key: 'Salt', value: 0 },
              ],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Custom Fields',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Unit',
              value: ['g'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Refercence Quantity',
              value: [],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Size',
              value: [],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Enable Service Size',
              value: [],
            },
          },
        },
        {
          id: 'Ingredients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Ingredients & Allergens'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Ingredients',
              value: [],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Select Allergens',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Notes and Warnings',
              value: [],
            },
          },
        },
        {
          id: 'Distributor',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Distributor'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Responsible Distributor',
              value: [''],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Postal Code',
              value: [''],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'City',
              value: [''],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Street',
              value: [''],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Link',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'E-Mail',
              value: [''],
            },
            '8': {
              feId: '',
              change: true,
              translate: false,
              name: 'Produced in',
              value: [''],
            },
          },
        },
        {
          id: 'Storage',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Storage Instructions'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Storage Fields',
              value: [
                { key: 'Temperature', value: '' },
                { key: 'Humidity', value: '' },
              ],
            },
          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Product Information'],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Widget Headline',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Description',
              value: ['Lorem ipsum'],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Basic Information',
              value: [
                { key: 'EAN', value: '435465464565' },
                { key: 'Amount', value: '700 ml' },
                { key: 'Type', value: 'Dry' },
                { key: 'Year', value: '2021' },
                { key: 'Alcohol Level', value: '13.5%' },
              ],
            },
          },
        },
        {
          id: 'Nutrients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Nutrients'],
            },
            '2': {
              feId: '',
              change: true,
              translate: false,
              name: 'Nutrients',
              value: [
                { key: 'Energy', value: 0 },
                {
                  key: 'Fat',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Saturated Fat',
                      value: 0,
                    },
                    {
                      key: 'Unsaturated Fat',
                      value: 0,
                    },
                  ],
                },
                {
                  key: 'Carbohydrates',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Sugar',
                      value: 0,
                    },
                    {
                      key: 'Polyhydric Alcohols',
                      value: 0,
                    },
                    {
                      key: 'Starch',
                      value: 0,
                    },
                  ],
                },
                { key: 'Dietary Fiber', value: 0 },
                { key: 'Protein', value: 0 },
                { key: 'Salt', value: 0 },
              ],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Custom Fields',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Unit',
              value: ['g'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Refercence Quantity',
              value: [],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Size',
              value: [],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Enable Service Size',
              value: [],
            },
          },
        },
        {
          id: 'Ingredients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Ingredients & Allergens'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Ingredients',
              value: [],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Select Allergens',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Notes and Warnings',
              value: [],
            },
          },
        },
        {
          id: 'Distributor',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Distributor'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Responsible Distributor',
              value: [''],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Postal Code',
              value: [''],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'City',
              value: [''],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Street',
              value: [''],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Link',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'E-Mail',
              value: [''],
            },
            '8': {
              feId: '',
              change: true,
              translate: false,
              name: 'Produced in',
              value: [''],
            },
          },
        },
        {
          id: 'Storage',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Storage Instructions'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Storage Fields',
              value: [
                { key: 'Temperature', value: '' },
                { key: 'Humidity', value: '' },
              ],
            },
          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Product Information'],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Widget Headline',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Description',
              value: ['Lorem ipsum'],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Basic Information',
              value: [
                { key: 'EAN', value: '435465464565' },
                { key: 'Amount', value: '700 ml' },
                { key: 'Type', value: 'Dry' },
                { key: 'Year', value: '2021' },
                { key: 'Alcohol Level', value: '13.5%' },
              ],
            },
          },
        },
        {
          id: 'Nutrients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Nutrients'],
            },
            '2': {
              feId: '',
              change: true,
              translate: false,
              name: 'Nutrients',
              value: [
                { key: 'Energy', value: 0 },
                {
                  key: 'Fat',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Saturated Fat',
                      value: 0,
                    },
                    {
                      key: 'Unsaturated Fat',
                      value: 0,
                    },
                  ],
                },
                {
                  key: 'Carbohydrates',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Sugar',
                      value: 0,
                    },
                    {
                      key: 'Polyhydric Alcohols',
                      value: 0,
                    },
                    {
                      key: 'Starch',
                      value: 0,
                    },
                  ],
                },
                { key: 'Dietary Fiber', value: 0 },
                { key: 'Protein', value: 0 },
                { key: 'Salt', value: 0 },
              ],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Custom Fields',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Unit',
              value: ['g'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Refercence Quantity',
              value: [],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Size',
              value: [],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Enable Service Size',
              value: [],
            },
          },
        },
        {
          id: 'Ingredients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Ingredients & Allergens'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Ingredients',
              value: [],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Select Allergens',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Notes and Warnings',
              value: [],
            },
          },
        },
        {
          id: 'Distributor',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Distributor'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Responsible Distributor',
              value: [''],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Postal Code',
              value: [''],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'City',
              value: [''],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Street',
              value: [''],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Link',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'E-Mail',
              value: [''],
            },
            '8': {
              feId: '',
              change: true,
              translate: false,
              name: 'Produced in',
              value: [''],
            },
          },
        },
        {
          id: 'Storage',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Storage Instructions'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Storage Fields',
              value: [
                { key: 'Temperature', value: '' },
                { key: 'Humidity', value: '' },
              ],
            },
          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Product Information'],
            },
            '2': {
              feId: '',
              change: false,
              translate: false,
              name: 'Show Widget Headline',
              value: ['X'],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Description',
              value: ['Lorem ipsum'],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Basic Information',
              value: [
                { key: 'EAN', value: '435465464565' },
                { key: 'Amount', value: '700 ml' },
                { key: 'Type', value: 'Dry' },
                { key: 'Year', value: '2021' },
                { key: 'Alcohol Level', value: '13.5%' },
              ],
            },
          },
        },
        {
          id: 'Nutrients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Nutrients'],
            },
            '2': {
              feId: '',
              change: true,
              translate: false,
              name: 'Nutrients',
              value: [
                { key: 'Energy', value: 0 },
                {
                  key: 'Fat',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Saturated Fat',
                      value: 0,
                    },
                    {
                      key: 'Unsaturated Fat',
                      value: 0,
                    },
                  ],
                },
                {
                  key: 'Carbohydrates',
                  value: 0,
                  subvalues: [
                    {
                      key: 'Sugar',
                      value: 0,
                    },
                    {
                      key: 'Polyhydric Alcohols',
                      value: 0,
                    },
                    {
                      key: 'Starch',
                      value: 0,
                    },
                  ],
                },
                { key: 'Dietary Fiber', value: 0 },
                { key: 'Protein', value: 0 },
                { key: 'Salt', value: 0 },
              ],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Custom Fields',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Unit',
              value: ['g'],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Refercence Quantity',
              value: [],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Serving Size',
              value: [],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'Enable Service Size',
              value: [],
            },
          },
        },
        {
          id: 'Ingredients',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Ingredients & Allergens'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Ingredients',
              value: [],
            },
            '3': {
              feId: '',
              change: true,
              translate: true,
              name: 'Select Allergens',
              value: [],
            },
            '4': {
              feId: '',
              change: true,
              translate: true,
              name: 'Notes and Warnings',
              value: [],
            },
          },
        },
        {
          id: 'Distributor',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Distributor'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Responsible Distributor',
              value: [''],
            },
            '3': {
              feId: '',
              change: false,
              translate: false,
              name: 'Postal Code',
              value: [''],
            },
            '4': {
              feId: '',
              change: true,
              translate: false,
              name: 'City',
              value: [''],
            },
            '5': {
              feId: '',
              change: true,
              translate: false,
              name: 'Street',
              value: [''],
            },
            '6': {
              feId: '',
              change: true,
              translate: false,
              name: 'Link',
              value: [''],
            },
            '7': {
              feId: '',
              change: true,
              translate: false,
              name: 'E-Mail',
              value: [''],
            },
            '8': {
              feId: '',
              change: true,
              translate: false,
              name: 'Produced in',
              value: [''],
            },
          },
        },
        {
          id: 'Storage',
          open: false,
          show: true,
          attributes: {
            '1': {
              feId: '',
              change: false,
              translate: true,
              name: 'Widget Headline',
              value: ['Storage Instructions'],
            },
            '2': {
              feId: '',
              change: true,
              translate: true,
              name: 'Storage Fields',
              value: [
                { key: 'Temperature', value: '' },
                { key: 'Humidity', value: '' },
              ],
            },
          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

const Certificates: IWidget = {
  id: uuid(),
  name: 'Certificates',
  order: 0,
  category: 'Basic',
  open: false,
  label: {
    id: 'certificates',
    name: 'Certificates',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Certificates'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Show Widget Headline',
                value: ['X'],
              },

          },
        },
        {
          id: 'Naturland',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Certificate Name',
                value: ['Naturland'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: ' Certificate Picture',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Picture URL',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Number',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Organization',
                value: ['Naturland -Verband für ökologischen Landbau e.V.'],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Website',
                value: ['www.naturland.de'],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Verification Document',
                value: [''],
              },
              '10': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Criteria',
                value: [''],
              },
              '11': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification process',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Certificates'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Show Widget Headline',
                value: ['X'],
              },

          },
        },
        {
          id: 'Naturland',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Certificate Name',
                value: ['Naturland'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: ' Certificate Picture',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Picture URL',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Number',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Organization',
                value: ['Naturland -Verband für ökologischen Landbau e.V.'],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Website',
                value: ['www.naturland.de'],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Verification Document',
                value: [''],
              },
              '10': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Criteria',
                value: [''],
              },
              '11': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification process',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Certificates'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Show Widget Headline',
                value: ['X'],
              },

          },
        },
        {
          id: 'Naturland',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Certificate Name',
                value: ['Naturland'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: ' Certificate Picture',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Picture URL',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Number',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Organization',
                value: ['Naturland -Verband für ökologischen Landbau e.V.'],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Website',
                value: ['www.naturland.de'],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Verification Document',
                value: [''],
              },
              '10': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Criteria',
                value: [''],
              },
              '11': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification process',
                value: [''],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Certificates'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: 'Show Widget Headline',
                value: ['X'],
              },

          },
        },
        {
          id: 'Naturland',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: true,
                translate: true,
                name: 'Certificate Name',
                value: ['Naturland'],
              },
              '2': {
                feId: '',
                change: false,
                translate: false,
                name: ' Certificate Picture',
                value: [''],
              },
              '3': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Picture URL',
                value: [''],
              },
              '4': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certificate Number',
                value: [''],
              },
              '5': {
                feId: '',
                change: false,
                translate: false,
                name: 'Organization',
                value: ['Naturland -Verband für ökologischen Landbau e.V.'],
              },
              '6': {
                feId: '',
                change: false,
                translate: false,
                name: 'Website',
                value: ['www.naturland.de'],
              },
              '7': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '8': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Year',
                value: [''],
              },
              '9': {
                feId: '',
                change: false,
                translate: false,
                name: 'Verification Document',
                value: [''],
              },
              '10': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification Criteria',
                value: [''],
              },
              '11': {
                feId: '',
                change: false,
                translate: false,
                name: 'Certification process',
                value: [''],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

const Sustainability: IWidget = {
  id: uuid(),
  name: 'Sustainability',
  order: 0,
  category: 'Sustainability',
  open: false,
  label: {
    id: 'sustainability',
    name: 'Sustainability',
    color: 'black',
  },
  content: [
    {
      lang: 'en',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Sustainability'],
              },
              '2': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },
              '3': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },

          },
        },
      ],
    },
    {
      lang: 'de',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Sustainability'],
              },
              '2': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },
              '3': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },

          },
        },
      ],
    },
    {
      lang: 'es',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Sustainability'],
              },
              '2': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },
              '3': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },

          },
        },
      ],
    },
    {
      lang: 'fr',
      childs: [
        {
          id: 'Head',
          open: true,
          show: true,
          attributes: {
              '1': {
                feId: '',
                change: false,
                translate: true,
                name: 'Widget Headline',
                value: ['Sustainability'],
              },
              '2': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },
              '3': {
                feId: '',
                change: false,
                translate: true,
                name: 'Description',
                value: ['Lorem Ipsum'],
              },

          },
        },
      ],
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

export const _widgets: IWidget[] = [
  // HeaderWidget,
  Rebuy,
  ProductHead,
  ProductInfo,
  FooterWidget,
  Certificates,
  Sustainability,
  // Documents,
  // Durability,
  // Instruction,
  // Recycling,
  // Warnings,
  // Traceability,
  // Co2Footprint,
  // StoreLocator,
];
